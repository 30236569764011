import request from '@/utils/request'


// 查询cgm读取列表
export function listCgmReading(query) {
  return request({
    url: '/cgmReading/cgmReading/list',
    method: 'get',
    params: query
  })
}

// 查询cgm读取分页
export function pageCgmReading(query) {
  return request({
    url: '/cgmReading/cgmReading/page',
    method: 'get',
    params: query
  })
}

// 查询cgm读取详细
export function getCgmReading(data) {
  return request({
    url: '/cgmReading/cgmReading/detail',
    method: 'get',
    params: data
  })
}

// 新增cgm读取
export function addCgmReading(data) {
  return request({
    url: '/cgmReading/cgmReading/add',
    method: 'post',
    data: data
  })
}

// 修改cgm读取
export function updateCgmReading(data) {
  return request({
    url: '/cgmReading/cgmReading/edit',
    method: 'post',
    data: data
  })
}

// 删除cgm读取
export function delCgmReading(data) {
  return request({
    url: '/cgmReading/cgmReading/delete',
    method: 'post',
    data: data
  })
}
